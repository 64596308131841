import { Col, Row } from 'antd';
import Link from 'next/link';
import { FC } from 'react';
import { BlogBreadcrumb, BlogJumbotron, PostItemListCard, Sidebar } from '../../BlogUi';
import { BlogLayoutProps } from './BlogLayout.props';
import styles from './styles.module.scss';

const BlogLayout: FC<BlogLayoutProps> = ({ children, appCode, featuredPost, jumbotron, footer, breadcrumbs }) => (
  <div>
    <header>
      <BlogBreadcrumb breadcrumbs={breadcrumbs} />
      <BlogJumbotron description={jumbotron.description} title={jumbotron.title} searchResultNr={0} />
    </header>
    <div className={styles.pageBody}>
      <div className={styles.container}>
        {!!featuredPost && (
          <Link className={styles.featuredPost} href={`/${featuredPost.attributes?.slug}`}>
            <PostItemListCard post={featuredPost} isFeaturedPost />
          </Link>
        )}
        <Row gutter={24} className={styles.content}>
          <Col flex={'auto'}>
            <main>{children}</main>
          </Col>
          <Col className={styles.sidebarCol}>
            <Sidebar appCode={appCode} />
          </Col>
        </Row>
        {footer && (
          <Row>
            <Col flex="auto">{footer}</Col>
          </Row>
        )}
      </div>
    </div>
  </div>
);

export { BlogLayout };
